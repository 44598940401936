import { ASSET_PREFIX } from "utils/constants";

const FavouriteButton = ({ favourited, onToggle, style }) => (
  <div
    className='container'
    style={style}
    onClick={e => {
      e.stopPropagation(); // Prevent onClick method of the containing PrizeCard being called.
      onToggle();
    }}
  >
    <img
      src={`${ASSET_PREFIX}/images/prizes/favourite-heart${favourited ? '-filled' : ''}.svg`}
      className='icon'
    />

    <style jsx>{`
      .container {
        padding: 0.7em; // Increase the touch area
        margin-left: -0.7em;
        margin-bottom: -0.7em;
        width: auto;
      }
      
      .icon {
        display: block;
        width: 28px;
        height: 28px;
      }
    `}</style>
  </div>
);

export default FavouriteButton;
