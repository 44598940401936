import { getPrizeTierByValue } from '../../constants/LottoPrizeValues';
import React from 'react';

interface IPrizeTierBadgeProps { 
  className?: string
  prizeValue: number
  style?: React.CSSProperties
}

const PrizeTierBadge = ({
  className = undefined,
  prizeValue,
  style,
}: IPrizeTierBadgeProps) => {
  const prizeTierInfo = getPrizeTierByValue(prizeValue);
  if (!prizeTierInfo) return null;

  return (
    <div className={`${className ?? ""} prizeTierBadge`} style={style}>
      <img src={prizeTierInfo.badge} className="tierIcon" />

      <p className="title">
        {prizeTierInfo.title}
        {prizeTierInfo.subtitle && (
          <span className="subtitle">{prizeTierInfo.subtitle}</span>
        )}
      </p>

      <style jsx>
        {`
          .prizeTierBadge {
            background: white;
            position: relative;
            height: 30px;
            border-radius: 18px;
            display: flex;
            align-items: center;
            font-weight: 800;
            font-size: 0.8rem;
            padding: 0.7em 1em 0.7em 3.5em;
          }

          .tierIcon {
            position: absolute;
            height: 120%;
            top: -10%;
            left: -2px;
          }

          .title {
            font-weight: inherit;
            font-size: inherit;
            color: ${style?.color ?? prizeTierInfo.color};
            text-transform: uppercase;
            line-height: 1;
          }

          .subtitle {
            text-transform: none;
            font-weight: 600;
            font-style: italic;
            font-size: 0.85em;
          }

          :global(.tierAndCriteriaContainer .prizeTierBadge) {
            margin-left: -1%;
          }

          @media (max-width: 450px) {
            .prizeTierBadge {
              height: 25px;
              font-size: 0.6rem;
              padding-left: calc(30px + 0.4em);
              padding-right: 1em;
            }
          }

          @media (max-width: 360px) {
            .prizeTierBadge {
              font-size: 0.5rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PrizeTierBadge;
