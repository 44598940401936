// Admin page uses this list in dropdown menu in Prize Management tool.
import { ASSET_PREFIX, colors } from 'utils/constants';
import BallMatchIndicator from 'components/landing/PrizesSection/BallMatchIndicator';
import { ReactNode } from 'react';

// Used in dropdown on prize management admin page
export const displayNameForValue = (value: number) => {
  switch (value) {
    case 5:
    case 10:
      return `£${value.toLocaleString()}`;

    default: {
      const prizeTier = getPrizeTierByValue(value);
      return `£${value.toLocaleString()} (${prizeTier?.title ?? 'unused tier'})`;
    }
  }
};

export const LottoPrizeValues = [
  100000, // unreleased
  25000,
  10000,
  2500, // old
  2000,
  500, // old
  300,
  100, // old
  50,
  25,
  10, // old
  5, // old
];

// Prizes page uses this list as the possible filter options
export const LottoPrizeValueFilterOptions = LottoPrizeValues.filter((value) => {
  // Filter out the old/unreleased prize values.
  return ![2500, 500, 100, 10, 5].includes(value);
});

interface IPrizeTier { 
    key: string
    color: string
    title: string
    subtitle?: string
    badge: string
    examplePrizes: string
    value: number
    poundSigns: {
      solid: string
      faded: string
    },
    ballMatches: {
      indicator: ReactNode
      copy: string
    },

}

export const PRIZE_TIERS: Record<string, IPrizeTier> = {
  'DAYMAKERboost': {
    key: 'DAYMAKERboost',
    color: '#FF399E',
    title: 'DAYMAKER',
    subtitle: 'boost',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/daymaker-boost.png`,
    examplePrizes: "worth up to £100k",
    value: 100000,
    poundSigns: {
      solid: '£££££',
      faded: '',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={4} bonusBallMatch bonusBallColor="transparent" bonusBallOutlineColor="#FB55B0" />,
      copy: 'Match 4 + bonus',
    },
  },
  'DAYMAKER': {
    key: 'DAYMAKER',
    color: '#00C4A7',
    title: 'DAYMAKER',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/daymaker.png`,
    examplePrizes: "worth up to £25k",
    value: 25000,
    poundSigns: {
      solid: '££££',
      faded: '',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={4} bonusBallMatch bonusBallColor="transparent" bonusBallOutlineColor={colors.primaryBrandGray} />,
      copy: 'Match 4 + bonus',
    },
  },
  'Diamond': {
    key: 'Diamond',
    color: '#8430D6',
    title: 'Diamond',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/diamond.png`,
    examplePrizes: "worth up to £10k",
    value: 10000,
    poundSigns: {
      solid: '£££',
      faded: '',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={4} />,
      copy: 'Match 4',
    },
  },
  'Platinum': {
    key: 'Platinum',
    color: '#43BFE5',
    title: 'Platinum',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/platinum.png`,
    examplePrizes: "worth up to £2k",
    value: 2000,
    poundSigns: {
      solid: '££',
      faded: '£',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={3} bonusBallMatch bonusBallColor="transparent" bonusBallOutlineColor={colors.primaryBrandGray} />,
      copy: 'Match 3 + bonus',
    },
  },
  'Gold': {
    key: 'Gold',
    color: '#F7C366',
    title: 'Gold',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/gold.png`,
    examplePrizes: "worth up to £300",
    value: 300,
    poundSigns: {
      solid: '££',
      faded: '',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={3} />,
      copy: 'Match 3',
    },
  },
  'Silver': {
    key: 'Silver',
    color: '#9D9594',
    title: 'Silver',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/silver.png`,
    examplePrizes: "worth up to £50",
    value: 50,
    poundSigns: {
      solid: '£',
      faded: '£',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={2} bonusBallMatch bonusBallColor="transparent" bonusBallOutlineColor={colors.primaryBrandGray} />,
      copy: 'Match 2 + bonus',
    },
  },
  'Bronze': {
    key: 'Bronze',
    color: '#F99261',
    title: 'Bronze',
    badge: `${ASSET_PREFIX}/images/prizes/PrizeValueDropdownSelector/bronze.png`,
    examplePrizes: "worth up to £25",
    value: 25,
    poundSigns: {
      solid: '£',
      faded: '',
    },
    ballMatches: {
      indicator: <BallMatchIndicator mainNumberMatches={2} />,
      copy: 'Match 2',
    },
  },
};

export const getPrizeTierByName = (prizeTierName: keyof typeof PRIZE_TIERS) => {
  const tier = PRIZE_TIERS[prizeTierName];
  return tier ?? null;
};

export const getPrizeTierByValue = (prizeValue: number): IPrizeTier | undefined => {
  return Object.values(PRIZE_TIERS).find((prizeInfo) => prizeInfo.value === prizeValue);
};
