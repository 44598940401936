import React, { useEffect, useState } from 'react';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { ASSET_PREFIX } from 'utils/constants';

interface IImageWithFallbackProps extends ImageProps {
  fallbackSrc: string;
}


const ImageWithFallback = ({
  src,
  fallbackSrc,
  width,
  ...rest
}: IImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState(src);

  const loader = ({ src, width, quality }: ImageLoaderProps) => {
    return `${
      src.startsWith("https://") ? "" : ASSET_PREFIX
    }${src}?w=${width}&q=${quality || 75}`;
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      {...rest}
      width={width}
      loader={loader}
      src={imgSrc}
      onErrorCapture={() => {
        setImgSrc(fallbackSrc);
      }}
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
};

export default ImageWithFallback;
