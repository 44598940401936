import { ASSET_PREFIX, colors } from 'utils/constants';
import RoundedButton from "components/RoundedButton";

const UnfavouritePrizeModal = ({ prizeName, prizeHero, onConfirm, onClose }) => (
  <div className='container' id='dimmedBackground' onClick={(e) => e.target.id === 'dimmedBackground' && onClose && onClose()}>
    <div id='message'>
      { onClose &&
        <img id='closeButton' src={`${ASSET_PREFIX}/images/close_button_white.svg`} style={{ cursor: 'pointer', width: '20px' }} onClick={onClose}  alt='close button - cross'/>
      }

      <div className='bodyContainer flexCentered'>
        <h3>
          Are you <strong>Sure?</strong>
        </h3>

        <p>
          Are you sure you want to remove <strong>{prizeName}</strong> from your Wish List?
        </p>

        <RoundedButton onClick={onConfirm} style={{ margin: '1em', width: '100%', maxWidth: '220px' }}>
          Remove From List
        </RoundedButton>

        <a onClick={onClose} style={{ cursor: 'pointer' }}>
          Cancel
        </a>
      </div>
    </div>

    <style jsx>{`
     .container {
       position: fixed;
       top: 0;
       left: 0;
       width: 100%;
       max-width: 100%;
       height: 100%;
       background-color: rgba(55,55,55,0.8);
       z-index: 999;
     }

      #message {
        position: relative;
        background-color: white;
        background: url(${prizeHero});
        background-size: cover;
        background-position: bottom;
        color: ${colors.primaryBrandGray};
        top: 50%;
        left: 50%;
        width: 90%;
        max-width: 500px;
        max-height: 95%;
        overflow-y: auto;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 7px;
        box-shadow: 0 0 15px #333;
        z-index: 1000;
        text-align: center;
      }

      #closeButton {
        position: fixed;
        width: 20px;
        height: 20px;
        right: 16px;
        top: 16px;
      }
      
      .bodyContainer {
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%);
        width: 100%;
        margin-top: 12em;
        padding: 5em 3em 3em;
      }
    `}</style>
  </div>
);

export default UnfavouritePrizeModal;
