import { ASSET_PREFIX, colors } from 'utils/constants';
import FavouriteButton from 'components/prizes/FavouriteButton';
import React, { ReactNode, useEffect, useState } from 'react';
import { getCookie } from 'utils/cookie';
import { toggleFavouritedPrize } from 'api/prizes';
import UnfavouritePrizeModal from 'components/wish-list/UnfavouritePrizeModal';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useRouter } from 'next/router';
import CardWithBackgroundImage from 'components/CardWithBackgroundImage';
import PrizeTierBadge from 'components/prizes/PrizeTierBadge';
import {
  canPrizePageOrderIndexBeEdited,
  movePrize,
  removePrizePageOrdering,
} from 'api/admin';
import { observer } from 'mobx-react-lite';
import { Prize, TPrizeBase } from 'types/Prize';

interface IPrizeCardProps {
  prize: TPrizeBase;
  showPrizeTierBadge?: boolean;
  pageSectionForAdminSiteOnly?: boolean;
  onChangePrizePageOrdering?: () => void;
  additionalComponentBadge?: ReactNode;
  onClick?: (event: React.MouseEvent<any>) => void;
  href?: string;
  withFavouriteButton: boolean;
  setShowUnfavouritePrizeModal?: (show: boolean) => void;
  favourited?: boolean;
  setFavourited?: (value: boolean) => void;
  showLockedPrizeBannerIfApplicable: boolean;
  customImageOpacity?: number;
  additionalCardClass?: string;
}
const PrizeCard = observer(
  ({
    prize,
    showPrizeTierBadge,
    pageSectionForAdminSiteOnly,
    onChangePrizePageOrdering,
    additionalComponentBadge,
    onClick,
    href,
    withFavouriteButton,
    setShowUnfavouritePrizeModal,
    favourited,
    setFavourited,
    showLockedPrizeBannerIfApplicable,
    customImageOpacity,
    additionalCardClass = undefined,
  }: IPrizeCardProps) => {
    const { value, playersChoice, prizeInstanceId, heroImageLocation } = prize;
    const [showAdminMovePrizeLeftArrow, setShowAdminMovePrizeLeftArrow] =
      useState(false);
    const [showAdminMovePrizeRightArrow, setShowAdminMovePrizeRightArrow] =
      useState(false);

    const { currentUser } = useUserStore();
    const router = useRouter();

    // Show locked prize banner if:
    // - User is a Freemium subscriber, and they're viewing a prize that doesn't cost £2,000 (the only prize tier for Freemium players), or
    // - User is a Lite subscriber, and they're viewing a £25,000 (which is only available to Premium players).
    const showLockedPrizeBanner =
      showLockedPrizeBannerIfApplicable &&
      ((currentUser?.subscriberStatus === 'FREEMIUM' && value !== 2000) ||
        (currentUser?.subscriberStatus === 'LITE' && value === 25000));

    const toggleFavourite = async (favourite: boolean) => {
      // If user is not logged in and they try to favourite a prize, redirect to /account/wish-list
      if (!currentUser) {
        return await router.push('/account/wish-list');
      }

      if (!favourite) {
        // Show the confirmation modal.
        setShowUnfavouritePrizeModal?.(true);
      } else {
        // Toggling on, so no modal, just do it.
        await toggleFavouritedPrize(
          getCookie('jwt'),
          prizeInstanceId,
          favourite
        );
        setFavourited?.(favourite);
      }
    };

    useEffect(() => {
      const loadPage = async () => {
        if (pageSectionForAdminSiteOnly) {
          const jwt = getCookie('jwt');
          const { canIncrement, canDecrement } =
            await canPrizePageOrderIndexBeEdited(
              jwt,
              pageSectionForAdminSiteOnly,
              prizeInstanceId
            );
          setShowAdminMovePrizeLeftArrow(canDecrement);
          setShowAdminMovePrizeRightArrow(canIncrement);
        }
      };

      loadPage();
    }, []);

    return (
      <CardWithBackgroundImage
        className={additionalCardClass}
        id="prizeCard"
        src={heroImageLocation} // TODO: Use WebP image wherever possible
        fallbackImagePath={heroImageLocation} // TODO: Should be fallbackImage (a JPEG fallback)
        imageOpacity={customImageOpacity || 0.8}
        onClick={onClick}
      >
        <div className="invisibleDivForSizing" />

        <a
          href={href ? href : undefined}
          rel="noopener noreferrer"
        >
          <div className="cardContents fullWidth flexCentered">
            {value && showPrizeTierBadge && (
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  position: 'absolute',
                  top: '5px',
                  left: '5px',
                  margin: 0,
                  right: '30px',
                }}
              >
                <PrizeTierBadge prizeValue={value} />
              </div>
            )}

            {pageSectionForAdminSiteOnly ? (
              <div
                className="removePrizeBadge"
                onClick={async () => {
                  const jwt = getCookie('jwt');
                  await removePrizePageOrdering(
                    jwt,
                    pageSectionForAdminSiteOnly,
                    prizeInstanceId
                  );
                  onChangePrizePageOrdering?.();
                }}
              >
                <div className="crossIcon flexCentered">
                  <span style={{ fontWeight: 600, marginTop: '-1px' }}>x</span>
                </div>
              </div>
            ) : (
              <h2
                style={{
                  fontWeight: 'normal',
                  fontSize: '30px',
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  margin: 0,
                  color: 'white',
                }}
              >
                +
              </h2>
            )}

            {additionalComponentBadge}

            {playersChoice && (
              <img
                src={`${ASSET_PREFIX}/images/prizes/players-choice.png`}
                style={{ height: 'auto', width: '80px', alignSelf: 'center' }}
                alt="players' choice"
              />
            )}
          </div>
        </a>

        <div style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}>
          {pageSectionForAdminSiteOnly &&
            (showAdminMovePrizeLeftArrow || showAdminMovePrizeRightArrow) && (
              <div className="fullWidth flexRow flexSpaceBetween">
                {showAdminMovePrizeLeftArrow ? (
                  <img
                    src={`${ASSET_PREFIX}/images/common/arrow-left-style1.svg`}
                    className="arrow"
                    alt="Left arrow"
                    onClick={async () => {
                      const jwt = getCookie('jwt');
                      await movePrize(
                        jwt,
                        pageSectionForAdminSiteOnly,
                        prizeInstanceId,
                        true
                      );
                      onChangePrizePageOrdering?.();
                    }}
                  />
                ) : (
                  <div />
                )}

                {showAdminMovePrizeRightArrow && (
                  <img
                    src={`${ASSET_PREFIX}/images/common/arrow-right-style1.svg`}
                    className="arrow"
                    alt="Right arrow"
                    onClick={async () => {
                      const jwt = getCookie('jwt');
                      await movePrize(
                        jwt,
                        pageSectionForAdminSiteOnly,
                        prizeInstanceId
                      );
                      onChangePrizePageOrdering?.();
                    }}
                  />
                )}
              </div>
            )}

          {withFavouriteButton && (
            <FavouriteButton
              favourited={favourited}
              onToggle={() => toggleFavourite(!favourited)}
              style={{
                position: 'absolute',
                left: 5,
                bottom: showLockedPrizeBanner ? 50 : 5,
              }}
            />
          )}

          {showLockedPrizeBanner && (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                padding: '0.5em',
                color: colors.primaryBrandGray,
                whiteSpace: 'break-spaces',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '3px 3px 10px 10px',
              }}
            >
              <img
                src={`${ASSET_PREFIX}/images/prizes/padlock.svg`}
                alt=""
                style={{
                  height: '15px',
                  marginRight: '1em',
                }}
              />
              <p
                style={{
                  fontWeight: 900,
                  marginBottom: 0,
                  fontSize: '0.9em',
                }}
              >
                {value === 25000 ? 'Premium' : 'Premium/Lite'} Prize
              </p>
            </div>
          )}
        </div>

        <style jsx>
          {`
            .cardContents {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
            }

            .invisibleDivForSizing {
              padding-top: 100%;
            }

            .removePrizeBadge {
              position: absolute;
              top: 0.6em;
              right: 0.6em;
              margin: 0;
              border: 2px solid white;
              border-radius: 100%;
              padding: 1em;
            }

            .removePrizeBadge:hover {
              background: #ffffff40;
            }

            .removePrizeBadge .crossIcon {
              position: absolute;
              top: 50%;
              left: 0;
              font-weight: 800;
              font-size: 1.25em;
              width: 100%;
              color: white;
              line-height: 0;
            }

            .arrow {
              margin: 0.2em;
              width: 15%;
              cursor: pointer;
            }
          `}
        </style>
      </CardWithBackgroundImage>
    );
  }
);

interface IPrizeCardWithTitleBelowProps extends IPrizeCardProps {
  style?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  subheadingStyle?: React.CSSProperties;
  redeemPrizeButton?: ReactNode;
}

const PrizeCardWithTitleBelow = ({
  prize,
  withFavouriteButton,
  onClick,
  href,
  style,
  titleStyle,
  subheadingStyle,
  showLockedPrizeBannerIfApplicable = false,
  showPrizeTierBadge = true,
  pageSectionForAdminSiteOnly,
  onChangePrizePageOrdering,
  customImageOpacity,
  redeemPrizeButton = undefined,
  additionalCardClass = '',
}: IPrizeCardWithTitleBelowProps) => {
  if (!prize) return null;

  const { title, subheading, code, prizeInstanceId, heroImageLocation } = prize;

  const [favourited, setFavourited] = useState(prize.favourited);
  const [showUnfavouritePrizeModal, setShowUnfavouritePrizeModal] =
    useState(false);

  const additionalComponentBadge =
    code === 'LASTMINUTE' ? (
      <div
        style={{
          color: 'white',
          fontStyle: 'italic',
          fontWeight: 'bold',
          fontSize: '0.8em',
        }}
      >
        in partnership with
        <br />
        <img
          src={`${ASSET_PREFIX}/images/prizes/lastminute.jpg`}
          style={{ width: '90%', maxWidth: '100px', marginTop: '0.5em' }}
          alt="lastminute.com logo"
        />
      </div>
    ) : null;

  return (
    <div
      className="prizeCardWithTitleBelow"
      style={style}
    >
      {showUnfavouritePrizeModal && (
        <UnfavouritePrizeModal
          onConfirm={async () => {
            await toggleFavouritedPrize(
              getCookie('jwt'),
              prizeInstanceId,
              false
            );
            setFavourited(false);
            setShowUnfavouritePrizeModal(false);
          }}
          onClose={() => setShowUnfavouritePrizeModal(false)}
          prizeName={title}
          prizeHero={heroImageLocation}
        />
      )}

      <PrizeCard
        additionalCardClass={additionalCardClass}
        prize={prize}
        pageSectionForAdminSiteOnly={pageSectionForAdminSiteOnly}
        showPrizeTierBadge={showPrizeTierBadge}
        additionalComponentBadge={additionalComponentBadge}
        onChangePrizePageOrdering={onChangePrizePageOrdering}
        onClick={onClick}
        href={href}
        setShowUnfavouritePrizeModal={setShowUnfavouritePrizeModal}
        favourited={favourited}
        setFavourited={setFavourited}
        withFavouriteButton={withFavouriteButton}
        showLockedPrizeBannerIfApplicable={showLockedPrizeBannerIfApplicable}
        customImageOpacity={customImageOpacity}
      />

      <a
        className="no-underline cursor-pointer"
        onClick={onClick}
        href={href}
      >
        <p
          className="prizeTitle"
          style={{ ...titleStyle }}
        >
          {title}
        </p>

        <p
          className="prizeSubheading"
          style={{ ...subheadingStyle }}
        >
          {subheading}
        </p>
      </a>
      {redeemPrizeButton && redeemPrizeButton}

      <style jsx>{`
        .prizeCardWithTitleBelow {
          min-width: 140px;
          width: 45%;
          max-width: 320px;
          margin: 1em 0.5em 1em 0.5em;
        }

        :global(.prizeCardWithTitleBelow .cardWithBackgroundImage) {
          position: relative;
          background-color: black;
          border-radius: 15px;
          box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.15);
          padding: 2%;
          cursor: ${onClick ? 'pointer' : 'default'};
        }

        .prizeTitle,
        .prizeSubheading {
          margin: 0.5em;
          line-height: 1.35;
        }

        .prizeTitle {
          font-size: 1.1em;
          font-weight: 800;
        }

        .prizeSubheading {
          font-weight: 700;
        }

        a {
          white-space: normal;
          text-align: left;
        }

        @media (min-width: 700px) {
          .prizeCardWithTitleBelow {
            width: 30%;
          }

          .prizeTitle {
            font-size: 1.3em;
          }
        }
      `}</style>
    </div>
  );
};

export default PrizeCardWithTitleBelow;
