import ImageWithFallback from 'components/ImageWithFallback';
import { ImageProps } from 'next/image';
import { extendClassName } from 'utils/common';

const formatImageDir = (imageDir: string) => {
  if (imageDir === '/') {
    return imageDir;
  }

  let formattedImageDir = imageDir;
  if (!imageDir.startsWith('/')) {
    formattedImageDir = `/${formattedImageDir}`;
  }
  if (!imageDir.endsWith('/')) {
    formattedImageDir = `${formattedImageDir}/`;
  }
  return formattedImageDir;
};

interface ICardWithBackgroundImageProps extends Partial<ImageProps> {
  imageDirectory?: string;
  imageFname?: string;
  fallbackImagePath?: string;
  imageOpacity?: number;
}

const CardWithBackgroundImage = ({
  imageDirectory = "/",
  imageFname, // for when the image and fallback share the same directory and file name (excluding file extension)
  src,
  fallbackImagePath, // for when the image and fallback are located in different directories or have different file names
  imageOpacity,
  onClick,
  id,
  className,
  children,
  ...otherImageProps
}: ICardWithBackgroundImageProps) => {
  return (
    <div
      id={id}
      className={extendClassName(
        "cardWithBackgroundImage fullWidth flexCentered",
        className
      )}
      onClick={onClick}
    >
      <ImageWithFallback
        {...otherImageProps}
        src={
          src
            ? src
            : `/images${formatImageDir(imageDirectory)}${imageFname}.webp`
        }
        fallbackSrc={
          fallbackImagePath
            ? fallbackImagePath
            : `/images${formatImageDir(imageDirectory)}${imageFname}.jpg`
        }
        layout="fill"
        objectFit="cover"
        className="bgImage"
      />

      <div className="children fullWidth flexCentered">{children}</div>

      <style jsx>
        {`
          .cardWithBackgroundImage {
            position: relative;
            overflow: hidden;
            ${!children ? "align-self: stretch;" : ""}
          }

          :global(#${id} .bgImage) {
            position: absolute;
            ${imageOpacity ? `opacity: ${imageOpacity};` : ""}
          }

          .children {
            position: relative;
            height: 100%;
          }
        `}
      </style>
    </div>
  );
};

export default CardWithBackgroundImage;
