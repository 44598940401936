import { colors } from 'utils/constants';

interface IBallMatchIndicatorProps {
  mainNumberMatches: number;
  bonusBallMatch?: boolean;
  bonusBallColor?: string;
  bonusBallOutlineColor?: string;
  style?: React.CSSProperties
}

const BallMatchIndicator = ({
  mainNumberMatches,
  bonusBallMatch,
  bonusBallColor = "#5AE2C0",
  bonusBallOutlineColor,
  style,
}: IBallMatchIndicatorProps) => {
  return (
    <div className="ballMatchIndicator" style={style}>
      {Array(mainNumberMatches)
        .fill(undefined)
        .map((_, i) => (
          <div
            key={i}
            className="ball"
            style={{
              backgroundColor: colors.primaryBrandGray,
              width: "10px",
              height: "10px",
              borderRadius: "5px",
            }}
          />
        ))}

      {bonusBallMatch && (
        <>
          {mainNumberMatches > 0 && (
            <p style={{ fontWeight: "bold", margin: "1px 2px 0" }}>+</p>
          )}
          <div
            className="ball"
            style={{
              backgroundColor: bonusBallColor,
              border: bonusBallOutlineColor
                ? `2px solid ${bonusBallOutlineColor}`
                : "",
              width: "10px",
              height: "10px",
              borderRadius: "5px",
            }}
          />
        </>
      )}

      <style jsx>{`
        .ballMatchIndicator {
          display: flex;
        }

        .ball {
          margin: 5px 4px;
        }

        .ball:first-child {
          margin-left: 0;
        }
      `}</style>
    </div>
  );
};

export default BallMatchIndicator;
